// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
	apiKey: "AIzaSyDONYPBMfDN7ZzH71g1ByqH8UnoozTNkq0",
	authDomain: "practice-da50d.firebaseapp.com",
	projectId: "practice-da50d",
	storageBucket: "practice-da50d.appspot.com",
	messagingSenderId: "385827166665",
	appId: "1:385827166665:web:58f2b4236f4b9b38e7f018",
	measurementId: "G-DBNFYWEM8Y"
}
